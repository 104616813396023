/* src/Congrats.css */

.congrats-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #000000;
    padding: 20px;
    position: relative;
}

.congrats-content {
    background: #1e1e1e;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 112, 255, 0.5);
    /* Duke Blue glow */
    max-width: 800px;
    width: 100%;
    text-align: center;
    color: #e0e0e0;
    animation: fadeIn 1s ease-in-out;
}

.congrats-content h1 {
    font-size: 2rem;
    color: #00aaff;
    margin-bottom: 20px;
}

.congrats-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.return-button {
    padding: 12px 25px;
    background: #0070FF;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.return-button:hover {
    background: #005bb5;
    transform: translateY(-2px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .congrats-content {
        padding: 30px;
    }

    .congrats-content h1 {
        font-size: 1.5rem;
    }

    .congrats-content p {
        font-size: 1rem;
    }

    .return-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}