/* src/Form.css */

body {
    font-family: 'Carlito', Calibri, sans-serif;
    background-color: #000000;
    /* Pure black background */
    color: #e0e0e0;
    margin: 0;
    padding: 0;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.form-step {
    background: #1e1e1e;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 112, 255, 0.5);
    /* Duke Blue glow */
    width: 100%;
    max-width: 800px;
    /* Increased width */
    position: relative;
    overflow: hidden;
    animation: glow 10s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 20px rgba(0, 112, 255, 0.3);
    }

    to {
        box-shadow: 0 0 40px rgba(0, 112, 255, 0.7);
    }
}

@keyframes gradualBlur {
    from {
        filter: blur(0px);
    }
    to {
        filter: blur(10px);
    }
}

.form-step h2 {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 2rem;
    /* Increased font size */
    color: #00aaff;
    /* Duke Blue color */
}

.intro-message {
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: center;
}

.intro-info {
    font-size: 1.1rem;
    margin-bottom: 30px;
    text-align: center;
}

.form-step p.intro-message {
    background: #2c2c2c;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.form-step label {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    /* Increased font size */
}

.form-step input,
.form-step textarea {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #444;
    border-radius: 5px;
    background: #2c2c2c;
    color: #e0e0e0;
    font-size: 1.1rem;
    /* Increased font size */
    transition: border 0.3s ease;
}

.form-step select{
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 5px;
    background: #000000;
    background-image: none;
    color: #ffffff;
    font-size: 1.1rem;
}

.form-step input:focus,
.form-step select:focus,
.form-step textarea:focus {
    outline: none;
    border: 1px solid #00aaff;
    /* Duke Blue focus border */
}

.answer-textarea {
    height: 300px;
    /* Increased height for writing */
    resize: vertical;
    font-size: 1.1rem;
    /* Increased font size */
    transition: filter 0.3s ease, background-color 0.3s ease;
}

.word-count,
.time-left {
    font-size: 1.2rem;
    /* Increased font size */
}

.info-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.blurred {
    filter: blur(5px);
    transition: filter 0.3s ease, background-color 0.3s ease;
    animation: gradualBlur 7s forwards;
    /* Gradual blur over 7 seconds */
}

.progress-lost {
    background-color: rgba(255, 0, 0, 0.2);
    /* Red overlay */
}

.progress-loss-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.5);
    /* Semi-transparent red */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1.2rem;
    animation: fadeIn 0.5s ease-in-out;
}

.progress-loss-overlay p {
    margin-bottom: 20px;
}

.cancel-button {
    padding: 10px 20px;
    background: #ffffff;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
}

.cancel-button:hover {
    background: #dddddd;
}

.gentle-reminder {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 112, 255, 0.2);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
    color: #ffffff;
    animation: fadeInOut 14s forwards;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.alert {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    z-index: 1000;
    animation: slideIn 0.5s forwards;
    font-size: 1.1rem;
}

.alert.error {
    background: #ff4444;
    /* Red background for errors */
    color: #ffffff;
}

.alert.success {
    background: #00c851;
    /* Green background for success */
    color: #ffffff;
}

.alert.info {
    background: #0070FF;
    /* Blue background for info */
    color: #ffffff;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.button,
.next-button {
    width: 100%;
    padding: 15px;
    margin-top: 30px;
    background: #0070FF;
    /* Duke Blue */
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    /* Increased font size */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.button:hover,
.next-button:hover {
    background: #005bb5;
    /* Darker Duke Blue on hover */
    transform: translateY(-2px);
}

.button:disabled,
.next-button:disabled {
    background: #555555;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .form-step {
        padding: 30px;
    }

    .form-step h2 {
        font-size: 1.5rem;
    }

    .intro-message,
    .intro-info {
        font-size: 1rem;
    }

    .form-step label {
        font-size: 1rem;
    }

    .button,
    .next-button {
        font-size: 1rem;
    }

    .word-count,
    .time-left {
        font-size: 1rem;
    }

    .progress-loss-overlay {
        font-size: 1rem;
    }

    .gentle-reminder {
        font-size: 0.9rem;
    }
}